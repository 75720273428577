import React from 'react'
import config from '../../../data/config'

const styles = {
  footer: {
      backgroundColor: '#1E2345',
      color: 'white'
  }
};
const { footer } = styles;

const Footer = () => {
  return (
    <footer className='footer' style={footer}>
      <div className='container'>
        <div className='content has-text-centered'>
          <p>
            {config.copyright}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
